
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import DateFilterDropdown from '@/components/layout/topFilter/dateFilter/DateFilterDropdown.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import DateFilterCustom from '@/components/layout/topFilter/dateFilter/DateFilterCustom.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  name: 'MessagesFilter',
  components: { DateFilterCustom, TopFilterButton, TmDropdownDownload, DateFilterDropdown, TopFilter },
  props: {
    expand: {
      type: Boolean,
    },
    adminLayout: {
      type: Boolean,
    },
  },
  emits: ['update:expand'],
  setup() {
    const accounts = ref(reportingCategories[0])
    const dateFilter = ref('all')
    const customDate = ref()

    const { isSmMax } = useBreakpoints()

    return {
      accounts,
      dateFilter,
      isSmMax,
      customDate,
    }
  },
})
