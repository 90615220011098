
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import { formatDate } from '@/services/dateTimeService'
import MessagesDropdown from '@/components/pages/admin/workspace/users/usersDetailed/MessagesDropdown.vue'

export default defineComponent({
  components: {
    TmPerson,
    TmAvatar,
    TmStatus,
    TmTable,
    MessagesDropdown,
  },
  setup() {
    return {
      formatDate,
    }
  },
})
