import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_messages_dropdown = _resolveComponent("messages-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-account-slot": _withCtx(({ row }) => [
      (row.groupSent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_avatar, {
              "custom-icon": "supervisor_account",
              color: "blue-400",
              size: "medium",
              class: "mr-2"
            }),
            _createElementVNode("div", null, _toDisplayString(row.recipients) + " recipient" + _toDisplayString(row.recipients > 1 ? 's' : ''), 1)
          ]))
        : (_openBlock(), _createBlock(_component_tm_person, {
            key: 1,
            name: row.account.name || row.account.phone,
            "avatar-url": row.account.avatar,
            "avatar-color": row.account.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color"]))
    ]),
    "body-cell-message-slot": _withCtx(({ row }) => [
      _createTextVNode(_toDisplayString(row.message), 1)
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      (row.groupSent)
        ? (_openBlock(), _createBlock(_component_tm_status, {
            key: 0,
            color: "blue",
            outline: "",
            name: `${row.deliveryPercent}% Delivered`
          }, null, 8, ["name"]))
        : (_openBlock(), _createBlock(_component_tm_status, _normalizeProps(_mergeProps({ key: 1 }, row.status)), null, 16))
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_messages_dropdown)
    ]),
    _: 1
  }))
}