
import { defineComponent, ref } from 'vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import MessagesFilter from '@/components/pages/admin/workspace/users/usersDetailed/MessagesFilter.vue'
import MessagesTable from '@/components/pages/admin/workspace/users/usersDetailed/MessagesTable.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import { useModes } from '@/compositions/modes'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmEmptyState,
    MessagesFilter,
    MessagesTable,
    WhiteBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const expand = ref(false)
    const search = ref('')
    const selected = ref([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'From', value: 'account-slot', width: '20%' },
      { text: 'Message', value: 'message-slot', width: '80%', expandPossible: true },
      { text: 'Time received', value: 'date-slot', width: '10%', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref(getTableData('inboundSMS'))

    return {
      isEmptyMode,
      expand,
      search,
      selected,
      tableHeaders,
      tableItems,
    }
  },
})
